import { keyBy, mapValues, sortBy } from 'lodash';

export interface SelectOptions<T = any> {
  label: string;
  value: T;
  subLabel?: string;
  group?: string;
}

export function plainStringArrayToSelectOptions(
  plainStrings: string[]
): SelectOptions[] {
  return sortBy(plainStrings.map(plainStringToSelectOption), 'label');
}

function plainStringToSelectOption(plainString: string): SelectOptions {
  return {
    label: plainString,
    value: plainString,
  };
}

export function getSelectOptions<T>(
  items: T[],
  labelKey: keyof T | ((item: T) => string),
  valueKey: keyof T | ((item: T) => any),
  subLabelFn?: (item: T) => string
): SelectOptions[] {
  let getGroup = (item: T) => {
    if ((item as any).archivedAt) {
      return 'Archived';
    }

    if ((item as any).deletedAt) {
      return 'Deleted';
    }

    return 'Active';
  };

  let sorted = sortBy(
    items.map((item) => ({
      label: [
        labelKey instanceof Function ? labelKey(item) : (item as any)[labelKey],
        (item as any).archivedAt ? '(archived)' : null,
        (item as any).deletedAt ? '(deleted)' : null,
      ]
        .filter((r) => r !== null)
        .join(' '),
      subLabel: subLabelFn ? subLabelFn(item) : undefined,
      group: getGroup(item),
      value:
        valueKey instanceof Function ? valueKey(item) : (item as any)[valueKey],
    })),
    'label'
  );

  return sorted;
}

interface DiffArray<T> {
  toRemove: T[];
  toAdd: T[];
}

export function getDiffArrayOperation<T>(
  oldArray: T[],
  newArray: T[]
): DiffArray<T> {
  const toRemove = oldArray.filter((oldItem) => !newArray.includes(oldItem));
  const toAdd = newArray.filter((newItem) => !oldArray.includes(newItem));

  return {
    toRemove,
    toAdd,
  };
}

export function getSelectMapLabelToValue<T extends string | number | symbol>(
  options: SelectOptions[]
): Record<T, string> {
  return mapValues(keyBy(options, 'value'), 'label') as any;
}
