import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DomSanitizer } from '@angular/platform-browser';
import { Signatory } from '../../contracts.interface';

@Component({
  selector: 'etoh-signatory-content',
  templateUrl: './signatory-content.component.html',
  styleUrls: ['./signatory-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FlexLayoutModule],
})
export class SignatoryContentComponent {
  @Input() title: string;

  @Input() signatories: Signatory[];

  constructor(public domSanitizer: DomSanitizer) {}

  transform(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
