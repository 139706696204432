import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Contacts, RoleDatabase } from '@etoh/database/core';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyModule,
} from '@ngx-formly/core';
import { environment } from 'apps/desktop/src/environments/environment';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { Subject, takeUntil, tap } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { OfficesService } from '../../../features/offices/offices.service';
import { UserService } from '../../../user.service';
import { userForm } from '../../forms/user.form';

@Component({
  selector: 'etoh-user-creation',
  templateUrl: './user-creation.component.html',
  styleUrls: ['./user-creation.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    NzAlertModule,
    NzSpinModule,
    NzButtonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FormlyModule,
    NzEmptyModule,
  ],
})
export class UserCreationComponent implements OnInit {
  loadingProfile = true;
  loading = false;

  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[];

  profileError?: string;

  destroy$ = new Subject();

  private afAuth = inject(AngularFireAuth);
  private cd = inject(ChangeDetectorRef);
  private userService = inject(UserService);
  private message = inject(NzMessageService);
  private officeService = inject(OfficesService);
  private http = inject(HttpClient);
  public authService = inject(AuthService);

  ngOnInit(): void {
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.userService.cleverRedirect();
      }
    });

    this.http.get(`${environment.apiUrl}/users/getUserFromToken`).subscribe({
      next: async (role: any) => {
        const guessedRole: RoleDatabase = role?.role;

        this.loadingProfile = false;

        if (guessedRole === 'anonymous') {
          const user = await this.afAuth.currentUser;
          this.profileError = `Account not registered: ${user?.email}`;
          this.cd.detectChanges();

          return;
        }

        if (guessedRole === 'guest') {
          const contact: Contacts = role.contacts;
          this.model = {
            firstname: contact.firstname,
            lastname: contact.lastname,
          };
        }

        this.fields = [
          ...userForm,
          guessedRole === 'user'
            ? {
                key: 'officeId',
                type: 'select',
                className: 'ant-col ant-col-24',
                props: {
                  required: true,
                  label: 'Office',
                  placeholder: 'Choose an office',
                  options: this.officeService.getOfficesForOptions$().pipe(
                    tap((offices) => {
                      console.log('offices', offices);
                    })
                  ),
                },
              }
            : null,
        ].filter((r) => r) as any;

        this.cd.detectChanges();
      },
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public async submit() {
    this.loading = true;

    this.http
      .post(`${environment.apiUrl}/users/insertUserOne`, {
        input: {
          user: this.form.value,
        },
      })
      .subscribe({
        next: (res) => {
          this.message.create(
            'success',
            `Thanks for completing your profile and welcome to your application!`
          );
          this.loading = false;
          this.cd.detectChanges();

          this.userService.updateUser$.next(null);
        },
        error: (err) => {
          this.message.create('error', `Error: ${err.error?.message}`);
          this.loading = false;
          this.cd.detectChanges();
        },
      });
  }
}
