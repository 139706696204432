<div class="text-center">
  {{ title }}
</div>

<div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="space-around">
  @for (signatory of signatories; track signatory) {
    <div class="text-center">
      @if (signatory.signature) {
        <img
          width="256"
          [src]="transform(signatory.signature)"
          />
      }
      <div>
        <b>{{ signatory.date | date: 'mediumDate' }}</b>
      </div>
      <div>
        {{ signatory.name }}
      </div>
    </div>
  }
</div>
