import {
  Agreements,
  Items,
  SampleShipmentRequests,
  SampleShipments,
} from '../index';
import {
  Addresses,
  AgreementTerms,
  BuySpecs,
  Companies,
  CompanyAddress,
  Contacts,
  Contracts,
  Invoices,
  Logs,
  MatchingItemsBuySpecs,
  Offices,
  Reminders,
  Samples,
  ShipmentFulfillments,
  Trackings,
  Users,
  UsersBonus,
  UsersCosts,
  ValidationsContracts,
} from './graphql';

// https://stackoverflow.com/questions/70151766/typescript-record-of-keytype-value-types#70152284
type EnforceKeys<Key extends string, T extends Record<Key, unknown>> = {
  [K in keyof T as K extends Key ? K : never]: T[K];
};

export const DATA_TYPES = [
  'companies',
  'reminders',
  'users',
  'contacts',
  'addresses',
  'items',
  'buySpecs',
  'offices',
  'agreements',
  'agreementTerms',
  'history',
  'shipmentFulfillments',
  'matchingItemsBuySpecs',
  'sampleShipmentRequests',
  'sampleShipments',
  'invoices',
  'companyAddress',
  'samples',
  'logs',
  'trackings',
  'contracts',
  'validationsContracts',
  'usersCosts',
  'usersBonus',
] as const;

export type DataTypeKeys = typeof DATA_TYPES[number];

export type DataType = EnforceKeys<
  DataTypeKeys,
  {
    companies: Companies;
    reminders: Reminders;
    users: Users;
    contacts: Contacts;
    addresses: Addresses;
    items: Items;
    buySpecs: BuySpecs;
    offices: Offices;
    agreements: Agreements;
    agreementTerms: AgreementTerms;
    history: History;
    shipmentFulfillments: ShipmentFulfillments;
    matchingItemsBuySpecs: MatchingItemsBuySpecs;
    sampleShipmentRequests: SampleShipmentRequests;
    sampleShipments: SampleShipments;
    invoices: Invoices;
    companyAddress: CompanyAddress;
    samples: Samples;
    logs: Logs;
    trackings: Trackings;
    contracts: Contracts;
    validationsContracts: ValidationsContracts;
    usersBonus: UsersBonus;
    usersCosts: UsersCosts;
  }
>;

type DataTypeToRemove = 'history' | 'logs' | 'trackings';

// Should remove history!
export type StoreDataTypeKey = Exclude<DataTypeKeys, DataTypeToRemove>;

export function isDataType(value: string): value is DataTypeKeys {
  return DATA_TYPES.includes(value as DataTypeKeys);
}

export function isStoreDataType(value: string): value is StoreDataTypeKey {
  return (
    isDataType(value) &&
    value !== 'history' &&
    value !== 'logs' &&
    value !== 'trackings'
  );
}
