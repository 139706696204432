import {
  Component,
  ChangeDetectionStrategy,
  Type,
  SimpleChanges,
} from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyFieldProps,
} from '@ngx-formly/core';

import { FormlyFieldTextArea } from '@ngx-formly/ng-zorro-antd/textarea';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  NzUploadChangeParam,
  NzUploadFile,
  NzUploadXHRArgs,
} from 'ng-zorro-antd/upload';
import { AuthService } from '../../../auth/auth.service';
import { UserService } from '../../../user.service';

import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize, startWith, take } from 'rxjs';
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';

type TextAreaProps = FormlyFieldProps;

export interface FormlyTextAreaFieldConfig
  extends FormlyFieldConfig<TextAreaProps> {
  type: 'file' | Type<FormlyFieldTextArea>;
}

@Component({
  selector: 'etoh-upload',
  template: `
    <nz-upload
      nzType="drag"
      [nzMultiple]="true"
      [nzFileList]="fileList"
      (nzChange)="handleChange($event)"
      [nzCustomRequest]="uploadFile"
    >
      <p class="ant-upload-drag-icon">
        <i nz-icon nzType="inbox"></i>
      </p>
      <p class="ant-upload-text">Click or drag file to this area to upload</p>
      <p class="ant-upload-hint">Support for a single or bulk upload.</p>
    </nz-upload>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldUpload extends FieldType<
  FieldTypeConfig<TextAreaProps>
> {
  fileList: NzUploadFile[] = [];

  get value(): NzUploadFile[] {
    return this.formControl.value ?? [];
  }

  constructor(
    private msg: NzMessageService,
    private userService: UserService,
    private storage: AngularFireStorage
  ) {
    super();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.formControl.valueChanges
      .pipe(startWith(this.value))
      .pipe(take(1))
      .subscribe((value: NzUploadFile[]) => {
        console.log(value);
        this.fileList = this.value.map((file) => {
          return {
            ...file,
            status: 'done',
          };
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log('changes', changes);
  }

  handleChange({ file, fileList }: NzUploadChangeParam): void {
    const status = file.status;
    console.log({ file, fileList });
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'removed') {
      const valuesWithoutRemoved = this.value.filter(
        (value) => value.uid !== file.uid
      );

      this.formControl.setValue(valuesWithoutRemoved);
    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }

  uploadFile = (item: NzUploadXHRArgs) => {
    const file = item.file;
    const filePath = `${this.userService.userId$.value}/${file.uid}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    return task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((result) => {
            console.log(result);
            console.log(result, item.file, result);

            const fileToSave: NzUploadFile = {
              uid: file.uid,
              name: file.name,
              type: file.type,
              size: file.size,
              url: result,
            };

            (item as any)?.onSuccess(
              result,
              { ...fileToSave, status: 'done', thumbUrl: result },
              result
            );

            const files = [...this.value, fileToSave];

            this.formControl.setValue(files, { emitEvent: true });
          });
        })
      )
      .subscribe(
        (result: UploadTaskSnapshot | any) => {
          console.log(result);
          const event = { percent: 0 };
          event.percent = (result.bytesTransferred / result.totalBytes) * 100;
          (item as any).onProgress(event, item.file);
        },
        (err) => {
          (item as any).onError(err, item.file);
        }
      );
  };
}
