import { Validators } from '@angular/forms';
import { ConfigOption } from '@ngx-formly/core';
import { DatepickerTypeComponent } from './datepicker-type/datepicker-type.component';
import { ButtonTypeComponent } from './types/button/button.type.component';
import { CheckboxGroupComponent } from './types/checkbox-group/checkbox-group.component';
import { HtmlComponent } from './types/html/html.component';
import { FormlyFieldInputGroup } from './types/input-group/input-group.type';
import { PhoneTypeComponent } from './types/phone-type/phone-type.component';
import { RepeatTypeComponent } from './types/repeat/repeat.type';
import { FormlyFieldSelectType } from './types/select/select-type.component';
import { FormlyFieldTags } from './types/tags/tags-type.component';
import { TermsSelectComponent } from './types/terms-select/terms-select.component';
import { TextareaFieldTypeComponent } from './types/textarea/textarea.component';
import { FormlyFieldUpload } from './types/upload/upload-type.component';
import { FormlyFieldWysiwyg } from './types/wysiwyg/wysiwyg-type.component';
import { FormlyWrapperFormField } from './wrappers/form-field/formly-wrapper-form-field.component';
import { PreviewWrapper } from './wrappers/preview/preview-wrapper.component';
import { WrappersAccordeonComponent } from './wrappers/wrappers-accordeon/wrappers-accordeon.component';
import { WrappersDragAndDropComponent } from './wrappers/wrappers-drag-and-drop/wrappers-drag-and-drop.component';
import { SignatureTypeComponent } from './types/signature-type/signature-type.component';

function maxlengthValidationMessage(err: any, field: any) {
  return `This value should be less than ${field.templateOptions.maxLength} characters`;
}

export function customValidation(control: any, type: any) {
  if (true) {
    return { customValidation: 'Custom error message' };
  }

  /** There is no error */
  return null;
}

export const formlyConfig: ConfigOption = {
  types: [
    {
      name: 'tags',
      component: FormlyFieldTags,
      wrappers: ['form-field'],
    },
    {
      name: 'select',
      component: FormlyFieldSelectType,
      wrappers: ['form-field'],
    },
    {
      name: 'checkbox-group',
      component: CheckboxGroupComponent,
      wrappers: ['form-field'],
    },
    {
      name: 'terms-select',
      component: TermsSelectComponent,
      wrappers: ['form-field'],
    },
    {
      name: 'datepicker',
      component: DatepickerTypeComponent,
      wrappers: ['form-field'],
    },
    {
      name: 'wysiwyg',
      component: FormlyFieldWysiwyg,
      wrappers: ['form-field'],
    },
    {
      name: 'file',
      component: FormlyFieldUpload,
      wrappers: ['form-field'],
    },
    {
      name: 'repeat',
      component: RepeatTypeComponent,
    },
    {
      name: 'input',
      wrappers: ['form-field'],
      component: FormlyFieldInputGroup,
    },
    {
      name: 'phone',
      wrappers: ['form-field'],
      component: PhoneTypeComponent,
    },
    {
      name: 'button',
      wrappers: ['form-field'],
      component: ButtonTypeComponent,
    },
    {
      name: 'html',
      wrappers: ['form-field'],
      component: HtmlComponent,
    },
    {
      name: 'textarea',
      wrappers: ['form-field'],
      component: TextareaFieldTypeComponent,
    },
    {
      name: 'signature',
      wrappers: ['form-field'],
      component: SignatureTypeComponent,
    },
  ],
  validationMessages: [
    { name: 'maxLength', message: maxlengthValidationMessage },
    { name: 'required', message: 'This field is required' },
    { name: 'email', message: 'Not email formatting' },
  ],
  validators: [
    { name: 'customValidation', validation: customValidation },
    { name: 'email', validation: Validators.email },
  ],
  wrappers: [
    { name: 'accordion', component: WrappersAccordeonComponent },
    { name: 'drag', component: WrappersDragAndDropComponent },
    {
      name: 'form-field',
      component: FormlyWrapperFormField,
    },
    {
      name: 'preview',
      component: PreviewWrapper,
    },
  ],
};
