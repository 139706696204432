<div class="card mat-padding-2" fxLayout="column" fxLayoutGap="16px">
  <nz-empty
    nzNotFoundContent="To access the application, you need to complete your profile before"
  ></nz-empty>

  @if (loadingProfile) {
    <nz-spin nzSize="large"></nz-spin>
  }

  @if (profileError) {
    <div>
      <nz-alert nzType="error" [nzMessage]="profileError"></nz-alert>
      <br />
      <div class="text-center">
        <button (click)="authService.logout()" nz-button nzType="primary">
          Switch to another account
        </button>
      </div>
    </div>
  }

  @if (fields) {
    <form
      nz-form
      [formGroup]="form"
      nzLayout="vertical"
      (ngSubmit)="submit()"
      >
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"
      ></formly-form>
      <button
        nz-button
        type="submit"
        nzType="primary"
        [disabled]="!form.valid"
        [nzLoading]="loading"
        >
        Save
      </button>
    </form>
  }
</div>
